import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';

export const AccessCodeRequestsGridColumns = ({
  handleAccessCodeRequest,
}: {
  handleAccessCodeRequest: (requestId: number, type: string) => Promise<void>;
}): GridColDef[] => [
  {
    flex: 0.5,
    minWidth: 60,
    field: 'id',
    headerName: 'Request ID',
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.row.id}</Typography>;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'organisationName',
    headerName: 'Organization Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row.organisationName ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 60,
    field: 'workEmail',
    headerName: 'Work Email',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row.workEmail ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'region',
    headerName: 'Region',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row.region ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'requestType',
    headerName: 'Request Type',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography>
          {params.row.requestType === 'general_request'
            ? 'General Request'
            : 'Domain verified'}
        </Typography>
      );
    },
  },
  {
    flex: 1,
    minWidth: 60,
    field: 'currentAction',
    headerName: 'Current Action',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row.currentAction ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'departmentName',
    headerName: 'Department',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row.departmentName ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'employeeId',
    headerName: 'Employee ID',
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.row.employeeId}</Typography>;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'action',
    headerName: 'Actions',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box display={'flex'} gap={1}>
          <Tooltip title="Approve">
            <IconButton
              onClick={() => handleAccessCodeRequest(params?.row?.id, '1')}
              sx={{
                color: 'var(--chip-green-color)',
              }}
            >
              {MaterialIcons.CheckIcon}
            </IconButton>
          </Tooltip>

          <Tooltip title="Reject">
            <IconButton
              onClick={() => handleAccessCodeRequest(params?.row?.id, '2')}
              sx={{
                color: 'var(--chip-red-color)',
              }}
            >
              {MaterialIcons.Close}
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  },
];
